import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import React from 'react'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { Route, Routes } from 'react-router'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useRestaurant } from '@hello-ai/for_r_app/src/models/Restaurant'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'

import ReservationsTab, {
  ReservationsStandardOrHigher,
  ReservationBookUnavailable,
} from './ReservationsTab'
import ReservationShowPage from 'pages/Restaurants/RestaurantReservations/Show'
import ReservationFormPage from 'pages/Restaurants/RestaurantReservations/Form'
import ReservationBlocksShowPage from 'pages/Restaurants/RestaurantReservationBlocks/Show'
import ReservationBlocksFormPage from 'pages/Restaurants/RestaurantReservationBlocks/Form'
import ReservationWalkinsShowPage from 'pages/Restaurants/RestaurantReservationWalkins/Show'
import ReservationWalkinsFormPage from 'pages/Restaurants/RestaurantReservationWalkins/Form'
import ReservationChangeSeatPage from 'pages/Restaurants/RestaurantReservations/ChangeSeat'
import ChangeRequestReservationPage from 'pages/Restaurants/RestaurantReservations/ChangeRequestReservation'
import ChangeRequestFormPage from 'pages/Restaurants/RestaurantReservations/ChangeRequestForm'
import RequestReservationShowPage from 'pages/Restaurants/RequestReservations/Show'
import RequestReservationFormPage from 'pages/Restaurants/RequestReservations/Form'
import SmartPayment from 'pages/Restaurants/SmartPayment'
import RevisionSmartPayment from 'pages/Restaurants/SmartPayment/RevisionSmartPayment'

const routes: StackRoutes = {
  Show: {
    title: t('予約詳細'),
    fullPath: '/restaurants/:id/reservations/:reservationId',
    relativePath: '/:reservationId',
    exact: true,
    element: <ReservationShowPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  Form: {
    title: t('予約編集'),
    fullPath: '/restaurants/:id/reservations/:reservationId/edit',
    relativePath: '/:reservationId/edit',
    exact: true,
    element: <ReservationFormPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  New: {
    title: t('予約新規作成'),
    fullPath: '/restaurants/:id/reservations/new',
    relativePath: '/new',
    exact: true,
    element: <ReservationFormPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  BlocksShow: {
    title: t('予約ブロック詳細'),
    fullPath:
      '/restaurants/:id/reservations/blocks/:restaurantReservationBlockId',
    relativePath: '/blocks/:restaurantReservationBlockId',
    exact: true,
    element: <ReservationBlocksShowPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  BlocksEdit: {
    title: t('ブロックを編集'),
    fullPath:
      '/restaurants/:id/reservations/blocks/:restaurantReservationBlockId/edit',
    relativePath: '/blocks/:restaurantReservationBlockId/edit',
    exact: true,
    element: <ReservationBlocksFormPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  BlocksNew: {
    title: t('ブロックを追加'),
    fullPath: '/restaurants/:id/reservations/blocks/new',
    relativePath: '/blocks/new',
    exact: true,
    element: <ReservationBlocksFormPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  WalkinsShow: {
    title: t('ウォークイン詳細'),
    fullPath: '/restaurants/:id/reservations/walkins/:restaurantReservationId',
    relativePath: '/walkins/:restaurantReservationId',
    exact: true,
    element: <ReservationWalkinsShowPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  WalkinsEdit: {
    title: t('ウォークインを編集'),
    fullPath:
      '/restaurants/:id/reservations/walkins/:restaurantReservationId/edit',
    relativePath: '/walkins/:restaurantReservationId/edit',
    exact: true,
    element: <ReservationWalkinsFormPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  WalkinsNew: {
    title: t('ウォークインを追加'),
    fullPath: '/restaurants/:id/reservations/walkins/new',
    relativePath: '/walkins/new',
    exact: true,
    element: <ReservationWalkinsFormPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  ChangeSeat: {
    title: t('席を変更する'),
    fullPath:
      '/restaurants/:id/reservations/:restaurantReservationId/change_seat',
    relativePath: '/:restaurantReservationId/change_seat',
    exact: true,
    element: <ReservationChangeSeatPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  ChangeRequest: {
    title: t('予約変更の承認'),
    fullPath:
      '/restaurants/:id/reservations/change_requests/:restaurantReservationId',
    relativePath: '/change_requests/:restaurantReservationId',
    exact: true,
    element: <ChangeRequestReservationPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  ChangeRequestForm: {
    title: t('予約変更の承認'),
    fullPath:
      '/restaurants/:id/reservations/change_requests/:restaurantReservationId/form',
    relativePath: '/change_requests/:restaurantReservationId/form',
    exact: true,
    element: <ChangeRequestFormPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  RequestReservationShow: {
    title: t('リクエスト受付予約'),
    fullPath:
      '/restaurants/:id/reservations/requests/:restaurantRequestReservationId',
    relativePath: '/requests/:restaurantRequestReservationId',
    exact: true,
    element: <RequestReservationShowPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  RequestReservationForm: {
    title: t('予約を追加'),
    fullPath:
      '/restaurants/:id/reservations/requests/:restaurantRequestReservationId/form',
    relativePath: '/requests/:restaurantRequestReservationId/form',
    exact: true,
    element: <RequestReservationFormPage />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  SmartPayment: {
    title: '',
    fullPath: '/restaurants/:id/reservations/:reservationId/smart_payment',
    relativePath: '/:reservationId/smart_payment',
    exact: true,
    element: <SmartPayment />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  RevisionSmartPayment: {
    title: '',
    fullPath:
      '/restaurants/:id/reservations/:reservationId/revision_smart_payment',
    relativePath: '/:reservationId/revision_smart_payment',
    exact: true,
    element: <RevisionSmartPayment />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
}

export default function ReservationStack() {
  const restaurantId = useRestaurantId()
  const { data: restaurant } = useRestaurant(restaurantId)

  if (restaurant == null) {
    return <Loading />
  }

  if (!restaurant.reservation_book_available) {
    return (
      <Routes>
        <Route
          path="/*"
          element={<ReservationBookUnavailable restaurant={restaurant} />}
        />
      </Routes>
    )
  }

  if (
    restaurant.reservation_book_plan_type === 'professional' ||
    restaurant.reservation_book_plan_type === 'standard' ||
    restaurant.reservation_book_plan_type === 'special'
  ) {
    return (
      <>
        <Routes>
          <Route
            path="/"
            element={<ReservationsStandardOrHigher restaurant={restaurant} />}
          />
        </Routes>
        <Stack routes={routes} />
      </>
    )
  }

  return <ReservationsTab />
}
