import React, { forwardRef, useCallback, useState } from 'react'
import {
  StyleSheet,
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputChangeEventData,
} from 'react-native'

import { Text } from './Text'
import { Colors } from '../constants/Colors'
import { useConfig } from './Config'

export type TextInputProps = React.ComponentProps<typeof RNTextInput> & {
  disabled?: boolean
  error?: boolean
  errorText?: string
  height?: number
}

function useFocus<Event>(props: {
  onFocus?: (event: Event) => void
  onBlur?: (event: Event) => void
}): [
  focused: boolean,
  focusEventHandlers: {
    onFocus: (event: Event) => void
    onBlur: (event: Event) => void
  },
] {
  const [focused, setFocused] = useState(false)

  const onFocus = useCallback(
    (event: Event) => {
      setFocused(true)
      props.onFocus?.(event)
    },
    [props]
  )

  const onBlur = useCallback(
    (event: Event) => {
      setFocused(false)
      props.onBlur?.(event)
    },
    [props]
  )

  return [focused, { onFocus, onBlur }]
}

export const TextInput = forwardRef<RNTextInput, TextInputProps>(
  (
    {
      disabled,
      onChange: onChange_,
      onChangeText: onChangeText_,
      onFocus: onFocus_,
      onBlur: onBlur_,
      error,
      style,
      multiline,
      errorText,
      height,
      ...props
    },
    ref
  ) => {
    const config = useConfig()
    const onChange = useCallback(
      (event: NativeSyntheticEvent<TextInputChangeEventData>) => {
        if (disabled) return
        onChange_?.(event)
      },

      [disabled, onChange_]
    )

    const onChangeText = useCallback(
      (text: string) => {
        if (disabled) return
        onChangeText_?.(text)
      },
      [disabled, onChangeText_]
    )

    const [focused, { onFocus, onBlur }] = useFocus({
      onFocus: onFocus_,
      onBlur: onBlur_,
    })

    return (
      <>
        <RNTextInput
          ref={ref}
          style={[
            {
              color: Colors.black,
              backgroundColor: Colors.field,
              fontSize:
                StyleSheet.flatten(style)?.fontSize ??
                config.TextInput.fontSize,
              borderRadius: 8,

              paddingHorizontal: 16,
              borderWidth: 2,
              borderColor: 'transparent',
              display: 'flex', // for storybook(appにも影響ないことを確認)
            },
            disabled && {
              color: Colors.textDisabled,
            },
            focused && {
              outlineColor: Colors.accent,
            },
            (error || errorText != null) && {
              borderColor: Colors.caution, // TODO: APIからのレスポンスを受け取る
            },
            !multiline && {
              height: height ?? config.TextInput.height,
            },
            multiline && {
              // paddingBottom, paddingTopをpaddingVerticalにまとめると、
              // multilineの場合にplaceholderの位置が正しくならないので注意
              paddingTop:
                ((height ?? config.TextInput.height) -
                  config.Text.fontSize -
                  7) /
                2,
              paddingBottom:
                ((height ?? config.TextInput.height) -
                  config.Text.fontSize -
                  7) /
                2,
            },
            style,
          ]}
          editable={!disabled}
          multiline={multiline}
          onChange={onChange}
          onChangeText={onChangeText}
          onFocus={onFocus}
          onBlur={onBlur}
          selectionColor={Colors.accent}
          placeholderTextColor={
            disabled ? Colors.textDisabled : Colors.secondaryBlack
          }
          {...props}
        />
        {errorText != null && (
          <Text
            style={{
              marginTop: 4,
              marginLeft: 16,
              fontSize: 12,
              lineHeight: 18,
              color: Colors.caution,
              fontWeight: '300',
            }}
          >
            {errorText}
          </Text>
        )}
      </>
    )
  }
)
