import React, { useState } from 'react'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Button } from '@hello-ai/ar_shared/src/components/Button'

import { View, Platform } from 'react-native'

import { useBroadcastMessages } from '../../../models/BroadcastMessage'
import BroadcastTable from '@hello-ai/for_r_app/src/components/Message/Broadcast/Table'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { useNavigate } from '../../../modules/navigation/useNavigate'

interface BroadcastListViewProps {
  restaurantId: number
}

export default function BroadcastListView({
  restaurantId,
}: BroadcastListViewProps) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const { broadcastMessages } = useBroadcastMessages(restaurantId, { page })

  const isWeb = Platform.OS === 'web'

  const goToHistory = (broadcastMessageId: string) => {
    if (isWeb) {
      navigate(
        `/restaurants/${restaurantId}/broadcasts/${broadcastMessageId}/history`
      )
    }
    navigation.navigate('BroadcastHistory', {
      restaurantId,
      broadcastMessageId,
    })
  }

  const goToEdit = (broadcastMessageId: string) => {
    if (isWeb) {
      navigate(
        `/restaurants/${restaurantId}/broadcasts/${broadcastMessageId}/edit`
      )
      return
    }
    navigation.navigate('BroadcastForm', {
      broadcastMessageId,
    })
  }

  return (
    <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
      <KeyboardAwareScrollView
        contentContainerStyle={{ paddingHorizontal: 48, paddingVertical: 24 }}
      >
        <Button
          mode="text"
          variant="primary"
          style={{
            backgroundColor: 'white',
            borderRadius: 24,
            marginBottom: 24,
          }}
          onPress={() => {
            if (isWeb) {
              navigate(`/restaurants/${restaurantId}/broadcasts/new`)
              return
            }
            navigation.navigate('BroadcastForm')
          }}
        >
          {({ textStyle }) => (
            <Text style={[{ fontSize: 14, fontWeight: '600' }, textStyle]}>
              {t('メッセージを作成')}
            </Text>
          )}
        </Button>
        <BroadcastTable
          broadcastItems={broadcastMessages?.data ?? []}
          onPressItem={(item) => {
            if (item.status === 'pending') {
              goToEdit(item.id)
            } else {
              goToHistory(item.id)
            }
          }}
          PaginationProps={{
            currentPage: broadcastMessages?.headerData?.currentPage ?? 0,
            totalPage: broadcastMessages?.headerData?.totalPages ?? 0,
            setPage: (page) => {
              setPage(page)
            },
          }}
        />
      </KeyboardAwareScrollView>
    </View>
  )
}
