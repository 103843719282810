import flatMap from 'lodash/flatMap'
import { Platform } from 'react-native'
import {
  Confirmation,
  DateTimeAndPartySize,
  Details,
  ReservationFormState,
  SelectSeats,
} from './ReservationForm'
import { RESERVATION_STEPPERS } from './FormCommon/Steppers'
import Loading from '../Shared/Loading'
import { displayToastSuccess } from '../Shared/Toast'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { ceil, toSeconds } from '../../modules/time'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  createRestaurantReservation,
  RestaurantReservation as RestaurantReservationModel,
  RestaurantReservationParams,
  updateRestaurantReservation,
  useRestaurantReservation,
  useRestaurantReservations,
} from '../../models/RestaurantReservation'
import React, { useState } from 'react'
import { persistReservationDate } from '../../modules/persistReservationDate'
import invariant from 'tiny-invariant'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { TableRestaurantSetting } from '@hello-ai/ar_shared/src/types/ForR/TableRestaurantSetting'
import { useNavigate } from '../../modules/navigation/useNavigate'

type ID = number | string
interface SchemaLike<T extends ID> {
  id: T
}
type Normalized<T> = {
  [P in keyof T]: T[P] extends Array<SchemaLike<number>>
    ? number[]
    : T[P] extends Array<SchemaLike<string>>
      ? string[]
      : T[P] extends SchemaLike<number>
        ? number
        : T[P] extends SchemaLike<string>
          ? string
          : T[P]
}

function getDefaultDateTime(dateTime: dayjs.Dayjs) {
  const defaultDateTime = dateTime.startOf('day').hour(18)

  if (defaultDateTime.isBefore(dayjs())) {
    return ceil(dayjs(), 15, 'minutes')
  }

  return defaultDateTime
}

export function initDefaultReservationFormState(
  date: dayjs.Dayjs,
  selectedStartTime?: number,
  selectedEndTime?: number,
  selectedSeatIds?: string[],
  adultPartySize?: number,
  childPartySize?: number
): ReservationFormState {
  const defaultDateTime = getDefaultDateTime(date)
  const startTime =
    selectedStartTime ??
    toSeconds(defaultDateTime.hour(), defaultDateTime.minute())
  const endTime =
    selectedEndTime ??
    toSeconds(defaultDateTime.hour() + 2, defaultDateTime.minute())

  return {
    dateString: defaultDateTime.format('YYYY-MM-DD'),
    startTime,
    endTime,
    adultPartySize: adultPartySize ?? 0,
    childPartySize: childPartySize ?? 0,
    selectedSeatIds: selectedSeatIds ?? [],
    selectedCustomerIds: [],
    selectedReservationCourses: [],
    allergy: '',
    memo: '',
  }
}

export function initReservationFormState(
  restaurantReservation: RestaurantReservationModel
): ReservationFormState {
  const startAt = dayjs(restaurantReservation.start_at)
  const startTime = toSeconds(startAt.hour(), startAt.minute())
  const endAt = dayjs(restaurantReservation.end_at)
  const endTime = toSeconds(endAt.hour(), endAt.minute())

  return {
    dateString: startAt.format('YYYY-MM-DD'),
    startTime,
    endTime,
    adultPartySize: restaurantReservation.adult_party_size,
    childPartySize: restaurantReservation.child_party_size,
    selectedSeatIds: restaurantReservation.table_seats.map(({ id }) => id),
    selectedCustomerIds: restaurantReservation.customers.map(({ id }) => id),
    selectedReservationCourses: restaurantReservation.reservation_courses.map(
      ({
        id,
        party_size: partySize,
        restaurant_course: { id: restaurantCourseId },
      }) => ({
        id,
        partySize,
        restaurantCourseId,
      })
    ),
    allergy: restaurantReservation.allergy,
    memo: restaurantReservation.memo,
    reservationRep: restaurantReservation.restaurant_crew_member,
  }
}

export function ReservationForm({
  restaurantId,
  restaurantReservationId,
  tableRestaurantSetting,
  dateString,
  startTime,
  endTime,
  selectedSeatIds,
  adultPartySize,
  childPartySize,
  initialStep,
  partySize,
  from,
  onUpdateStarted,
  onLoadDateTimeAndPartySize,
}: {
  restaurantId: number
  restaurantReservationId: string | undefined
  tableRestaurantSetting: Normalized<TableRestaurantSetting>
  dateString?: string | undefined
  startTime?: number | undefined
  endTime?: number | undefined
  selectedSeatIds?: string[] | undefined
  adultPartySize?: number
  childPartySize?: number
  initialStep?: number
  partySize?: number | undefined
  from?: 'map' | 'list' | 'chart'
  onUpdateStarted?: () => void
  onLoadDateTimeAndPartySize?: () => void
}) {
  const { width, sm } = useResponsive()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const token = useToken()
  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )

  const { restaurantReservations, mutate: restaurantReservationsMutate } =
    useRestaurantReservations(restaurantId, {
      date: dateString!,
    })
  const [step, setStep] = useState<number>(initialStep ?? 1)

  // フォームの初期値の日時は固定したいのでstateに入れておく
  const [defaultState] = useState(() =>
    initDefaultReservationFormState(
      dateString == null ? dayjs() : dayjs(dateString, 'YYYY-MM-DD'),
      startTime,
      endTime,
      selectedSeatIds,
      partySize ?? adultPartySize,
      childPartySize
    )
  )
  const [state, setState] = useFormState(
    restaurantReservation
      ? initReservationFormState(restaurantReservation)
      : defaultState
  )
  const [additionalModal, setAdditionalModal] = useState<
    'select_reservation_rep' | null
  >(null)

  const onPressGoBack = () => {
    if (step <= 1) {
      if (Platform.OS === 'web') {
        if (restaurantReservationId == null) {
          navigate(`/restaurants/${restaurantId}/reservations?m=chart`)
        } else {
          navigate(
            `/restaurants/${restaurantId}/reservations/${restaurantReservationId}`
          )
        }
      } else {
        navigation.goBack()
      }
      return
    }

    setStep((step) => step - 1)
  }

  const handleStepChange = async (
    value: Partial<ReservationFormState>,
    stepsToAdvance = 1
  ) => {
    const newState: ReservationFormState = {
      ...state,
      ...value,
    }
    setState(newState)

    const isLastStep =
      step >= RESERVATION_STEPPERS[RESERVATION_STEPPERS.length - 1].stepNumber

    if (isLastStep) {
      onUpdateStarted?.()

      const params: RestaurantReservationParams = {
        start_at: dayjs(newState.dateString)
          .startOf('day')
          .add(newState.startTime, 'second')
          .format('YYYY-MM-DD HH:mm'),
        end_at: dayjs(newState.dateString)
          .startOf('day')
          .add(newState.endTime, 'second')
          .format('YYYY-MM-DD HH:mm'),
        adult_party_size: newState.adultPartySize,
        child_party_size: newState.childPartySize,
        table_seat_ids: newState.selectedSeatIds,
        customer_ids: newState.selectedCustomerIds,
        reservation_courses: flatMap(
          newState.selectedReservationCourses,
          ({ id, partySize, restaurantCourseId: tableCourseId }) => {
            if (id == null && partySize === 0) return []
            return {
              id: id == null ? undefined : id,
              party_size: partySize,
              restaurant_course_id: tableCourseId!,
              ...(partySize === 0 ? { _destroy: '1' } : {}),
            }
          }
        ),
        allergy: newState.allergy,
        memo: newState.memo == null ? undefined : newState.memo,
        other_table_seats: undefined,
      }
      if (
        newState.reservationRep !== undefined &&
        newState.reservationRep !== null
      ) {
        params.restaurant_crew_member_id = newState.reservationRep.id
      }
      if (restaurantReservationId == null) {
        const { error } = await createRestaurantReservation(
          token,
          restaurantId,
          params
        )
        if (error != null) return
        await restaurantReservationsMutate()
        displayToastSuccess(
          t('予約を作成しました'),
          undefined,
          width < sm ? { marginBottom: 132 } : undefined
        )
      } else {
        const isExchangeMode = restaurantReservations
          .filter((i) => i.id !== restaurantReservationId)
          .some((i) =>
            i.table_seats
              .flatMap((t) => t.id)
              .some((tid) => params.table_seat_ids?.includes(tid))
          )
        if (isExchangeMode) {
          /**
           * NOTE
           * 予約１：テーブル１、２、３
           * 予約２：テーブルA、B、C
           * のとき、予約１をテーブル１、２，Aとしたい場合は
           * table_seat_ids = １、２、A
           * other_table_seats = ３、B、C
           * とする。
           * このとき、３を交換元、Aを交換先と呼ぶ
           */
          // 交換元のシートId 例) ３
          const releaseSeatIds = restaurantReservation?.table_seats.flatMap(
            (original) =>
              params.table_seat_ids?.includes(original.id) ? [] : [original.id]
          )
          const startAt = dayjs(params.start_at)
          params.other_table_seats = restaurantReservations.flatMap(
            (reservation) => {
              if (reservation.id === restaurantReservationId) return []
              // 交換先シート 例）A
              const exchangeSeatIds = reservation.table_seats.flatMap(
                (tableSeat) =>
                  params.table_seat_ids?.includes(tableSeat.id)
                    ? [tableSeat.id]
                    : []
              )
              if (exchangeSeatIds.length === 0) return []
              if (!startAt.isSame(reservation.start_at)) return [] // NOTE: 前後に同じ席をしている予約を除く
              // 交換先予約で交換しないシートIds 例) B、C
              const otherReservationNewSeatIds =
                reservation.table_seats.flatMap((tableSeat) => {
                  return !exchangeSeatIds.includes(tableSeat.id)
                    ? [tableSeat.id]
                    : []
                })
              // 交換作業 例) 3 <-> A
              exchangeSeatIds.forEach(() => {
                const releaseSeatId = releaseSeatIds?.shift()
                if (releaseSeatId != null) {
                  otherReservationNewSeatIds.push(releaseSeatId)
                }
              })

              return [
                {
                  reservation_id: reservation.id,
                  table_seat_ids: otherReservationNewSeatIds,
                },
              ]
            }
          )
        }

        const { error } = await updateRestaurantReservation(
          token,
          restaurantId,
          restaurantReservationId,
          params
        )
        if (error != null) return
        await restaurantReservationsMutate()
        displayToastSuccess(
          t('予約を更新しました'),
          undefined,
          width < sm ? { marginBottom: 132 } : undefined
        )
      }

      await persistReservationDate(dayjs(newState.dateString))
      // 予約作成・更新後に指定した日時にスクロールするためparamsを渡す必要がある
      // navigation.goBack()ではparamsが渡せないのでnavigateを使用する
      if (Platform.OS === 'web') {
        navigate(
          `/restaurants/${restaurantId}/reservations?m=${from ?? 'chart'}&date=${newState.dateString}`
        )
      } else {
        navigation.navigate('Reservations', {
          dateString: newState.dateString,
          startTime: newState.startTime,
          mode: from ?? 'chart',
        })
      }
      return
    } else if (
      step === 3 &&
      tableRestaurantSetting.select_reservation_rep_enabled
    ) {
      setAdditionalModal('select_reservation_rep')
      return
    }

    setStep((step) => step + stepsToAdvance)
  }

  const onPressNext = async (value: Partial<ReservationFormState>) => {
    await handleStepChange(value)
  }

  const onPressSkip = async (value: Partial<ReservationFormState>) => {
    await handleStepChange(value, 2)
  }

  const onPressNextForReservationRep = async (
    value: Partial<ReservationFormState>
  ) => {
    const newState: ReservationFormState = {
      ...state,
      ...value,
    }
    setState(newState)

    setStep((step) => step + 1)
  }

  if (restaurantReservationId != null && restaurantReservation === undefined) {
    return <Loading />
  }

  switch (step) {
    case 1:
      return (
        <DateTimeAndPartySize
          restaurantId={restaurantId}
          currentStep={step}
          restaurantReservation={restaurantReservation}
          state={state}
          onPressNext={onPressNext}
          onPressSkip={onPressSkip}
          onLoad={onLoadDateTimeAndPartySize}
        />
      )
    case 2:
      return (
        <SelectSeats
          restaurantId={restaurantId}
          currentStep={step}
          restaurantReservation={restaurantReservation}
          state={state}
          onPressGoBack={onPressGoBack}
          onPressNext={onPressNext}
        />
      )
    case 3:
      return (
        <Details
          restaurantId={restaurantId}
          currentStep={step}
          restaurantReservation={restaurantReservation}
          state={state}
          onPressGoBack={onPressGoBack}
          onPressNext={onPressNext}
          additionalModal={additionalModal}
          setAdditionalModal={setAdditionalModal}
          onPressNextForReservationRep={onPressNextForReservationRep}
        />
      )
    case 4:
      return (
        <Confirmation
          restaurantId={restaurantId}
          currentStep={step}
          restaurantReservation={restaurantReservation}
          state={state}
          onPressGoBack={onPressGoBack}
          onPressNext={onPressNext}
        />
      )
    default:
      invariant(true, `unknown step ${step}`)
  }
}
