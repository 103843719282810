import React, { useMemo, useState } from 'react'
import { View, Dimensions, Image, Platform } from 'react-native'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import {
  bulkDeleteTableProduct,
  bulkUpdateProductStatus,
  useArchivedRestaurantTableProducts,
} from '../../../models/TableProduct'
import {
  createResponsiveSheet,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'
import { CheckboxIcon } from '@hello-ai/ar_shared/src/components/Checkbox'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { getTaxRateText } from '../../../constants/TaxRates'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { useRestaurantCurrency } from '../../../modules/useRestaurantCurrency'
import { useRestaurant } from '../../../models/Restaurant'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import ConfirmDeleteArchivedProductModal from './ConfirmDeleteArchivedProductModal'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'

const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
  root: {
    flex: 1,
    backgroundColor: Colors.bgBlack,
    flexGrow: 1,
  },
  container: {
    paddingVertical: 16,
    paddingHorizontal: width < sm ? 16 : 24,
  },
  scrollViewContainer: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    height:
      Dimensions.get('window').height -
      (width < sm
        ? // NOTE: header + footer + padding + table header
          Platform.OS === 'web'
          ? 280
          : 320 // NOTE: Edge部分が見切れるのでリストは少し低めに設定する
        : // NOTE: header + footer + padding + table header
          238),
    paddingTop: width < sm ? 20 : 16,
    paddingHorizontal: width < sm ? 16 : 24,
  },
  tableContainer: {
    rowGap: width < sm ? 20 : 16,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: width < sm ? 20 : 16,
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
  },
  rowContent: {
    flexDirection: width < sm ? 'column' : 'row',
    marginLeft: width < sm ? 12 : 24,
  },
  pagination: {
    paddingVertical: 16,
    marginHorizontal: 0,
    justifyContent: 'center',
    columnGap: 20,
  },
  footerContainer: {
    flexDirection: width < sm ? 'column-reverse' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 12,
    columnGap: 16,
    backgroundColor: Colors.white,
    paddingVertical: width < sm ? 16 : 24,
    paddingHorizontal: width < sm ? 16 : 12,
  },
  footerButton: {
    width: width < sm ? '100%' : undefined,
    height: 48,
  },
  spNone: {
    display: width < sm ? 'none' : 'flex',
  },
}))

interface ArchivedListViewProps {
  restaurantId: number
}

export default function ArchivedListView({
  restaurantId,
}: ArchivedListViewProps) {
  const token = useToken()
  const style = useResponsiveStyle(responsiveStyle)
  const [productsCurrentPage, setProductsCurrentPage] = useState(1)
  const { tableProducts, allTableProducts, pagination, mutate } =
    useArchivedRestaurantTableProducts(restaurantId, {
      page: productsCurrentPage,
    })
  const [selectTableProductIds, setSelectTableProductIds] = useState<
    Set<string>
  >(new Set())
  const { data: restaurant } = useRestaurant(restaurantId)
  const { priceUnit, currencyWithDelimiter } = useRestaurantCurrency(restaurant)
  const [isVisibleConfirmCancel, setIsVisibleConfirmCancel] = useState(false)

  const checkedAll = useMemo(() => {
    return (
      allTableProducts?.length !== 0 &&
      allTableProducts?.length === selectTableProductIds.size
    )
  }, [allTableProducts, selectTableProductIds])

  return (
    <View style={style.root}>
      <View style={style.container}>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 8,
            marginBottom: 16,
          }}
          disabled={allTableProducts?.length === 0}
          onPress={() => {
            if (checkedAll) {
              setSelectTableProductIds(new Set())
            } else {
              setSelectTableProductIds(
                new Set(
                  allTableProducts?.map((tableProduct) => tableProduct.id) ?? []
                )
              )
            }
          }}
        >
          <CheckboxIcon checked={checkedAll} />
          <Text>{t('すべて選択')}</Text>
        </TouchableOpacity>
        <View style={style.scrollViewContainer}>
          <KeyboardAwareScrollView contentContainerStyle={style.tableContainer}>
            {tableProducts != null && tableProducts.length === 0 && (
              <Text
                style={{
                  paddingTop: 16,
                  marginBottom: 24,
                  paddingHorizontal: 24,
                  fontSize: 22,
                  fontWeight: '600',
                  color: Colors.disabledBlack,
                  textAlign: 'center',
                }}
              >
                {t('該当する商品はありません')}
              </Text>
            )}
            {tableProducts?.map((tableProduct, index) => {
              const checked = selectTableProductIds.has(tableProduct.id)
              return (
                <TouchableOpacity
                  key={tableProduct.id}
                  style={style.rowContainer}
                  onPress={() => {
                    setSelectTableProductIds((prev) => {
                      if (!checked) {
                        return new Set(prev).add(tableProduct.id)
                      }
                      prev.delete(tableProduct.id)
                      return new Set(prev)
                    })
                  }}
                >
                  <CheckboxIcon checked={checked} />
                  <View style={{}}>
                    <View style={style.rowContent}>
                      <Image
                        source={{ uri: tableProduct.image?.sm }}
                        defaultSource={require('../../../assets/images/no_image_product.png')}
                        style={[
                          {
                            borderRadius: 4,
                            width: 64,
                            height: 64,
                            marginRight: 16,
                          },
                          tableProduct.image == null && style.spNone,
                        ]}
                      />
                      <View style={{ justifyContent: 'center' }}>
                        <Text style={{ fontSize: 18, fontWeight: '600' }}>
                          {tableProduct.name}
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: '300',
                            color: Colors.black60,
                          }}
                        >
                          {tableProduct.table_menu_pages
                            ?.map((page) => page.name)
                            .join(',')}
                        </Text>
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Text
                            style={{
                              fontWeight: '300',
                              fontSize: 16,
                            }}
                          >
                            {tableProduct.prices.table?.price_type === 'fixed'
                              ? `${priceUnit} ${currencyWithDelimiter(
                                  tableProduct.prices.table.price
                                )}`
                              : 'ASK'}
                          </Text>
                          <View
                            style={{
                              marginLeft: 8,
                              padding: 4,
                              backgroundColor: Colors.bgBlack,
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: 2,
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: '300',
                                fontSize: 12,
                              }}
                            >
                              {tableProduct.prices.table &&
                                getTaxRateText(tableProduct.prices.table)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
              )
            })}
          </KeyboardAwareScrollView>
          <Pagination
            currentPage={productsCurrentPage}
            totalPage={pagination?.totalPages ?? 1}
            setPage={(page) => {
              setProductsCurrentPage(page)
            }}
            style={style.pagination}
          />
        </View>
      </View>
      <View style={style.footerContainer}>
        <Button
          mode="outline"
          variant="danger-secondary"
          style={[style.footerButton]}
          disabled={selectTableProductIds.size === 0}
          onPress={() => {
            setIsVisibleConfirmCancel(true)
          }}
        >
          {t('チェックしたリストを完全に削除する')}
        </Button>
        <AsyncButton
          style={style.footerButton}
          disabled={selectTableProductIds.size === 0}
          onPress={async () => {
            if (token == null) return
            const { error } = await bulkUpdateProductStatus(
              token,
              restaurantId,
              {
                ids: Array.from(selectTableProductIds),
                status: 'published',
              }
            )
            if (error != null) {
              onError(error)
              return
            }
            await mutate()
          }}
        >
          {t('チェックしたリストを元に戻す')}
        </AsyncButton>
      </View>
      <ConfirmDeleteArchivedProductModal
        title={t('{{count}}件のリストを完全に削除します', {
          count: selectTableProductIds.size,
        })}
        isModalVisible={isVisibleConfirmCancel}
        onPressCancel={() => setIsVisibleConfirmCancel(false)}
        onPressSubmit={async () => {
          if (token == null) {
            return
          }
          const { error } = await bulkDeleteTableProduct(token, restaurantId, {
            ids: Array.from(selectTableProductIds),
          })
          if (error != null) {
            onError(error)
            return
          }
          await mutate()
          setIsVisibleConfirmCancel(false)
        }}
      />
    </View>
  )
}
