import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH'
import { faUserAlt } from '@fortawesome/pro-solid-svg-icons/faUserAlt'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { useRestaurant } from 'models/Restaurant'

import RestaurantBusinessTimesStack from 'navigation/Restaurants/BusinessTimesStack'

import CustomersStack from 'navigation/Customers/Stack'
import RestaurantInformationStack from 'navigation/Restaurants/InformationStack'
import ReportsStack from 'navigation/Restaurants/ReportsStack'
import RestaurantTableOrdersStack from 'navigation/Restaurants/TableOrdersStack'
import RestaurantTableSeatsStack from 'navigation/Restaurants/TableSeatsStack'
import RestaurantSales from 'pages/Restaurants/Sales'
import TakeoutSales from 'pages/Restaurants/Takeout/Sales'

import Icon from 'components/Restaurant/Icon'
import {
  Drawer,
  DrawerHeaderIconRight,
  DrawerRouteKey,
  DrawerSection,
  DrawerSectionKey,
} from 'components/Shared/Navigation/Drawer'
import { DrawerHeader } from 'components/Shared/Navigation/DrawerHeader'
import { useCurrentOwner } from 'models/Owner'
import { View } from 'react-native'

import LinkOpacity from 'components/Shared/LinkOpacity'

import {
  faAddressBook,
  faBurgerSoda,
  faCalendarAlt,
  faChartLine,
  faClipboardList,
  faCog,
} from '@fortawesome/pro-solid-svg-icons'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { CustomerAttributes } from '@hello-ai/for_r_app/src/components/Customers/Attributes'
import { BusinessUserResource_Role } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_resource'
import GourmetSiteSettingIcon from 'components/GourmetSite/GourmetSiteSettingIcon'
import SyncFailedReservationTitle from 'components/GourmetSite/SyncFailedReservationTitle'
import { signOut, useAuthMe } from 'models/Auth'
import { useRestaurantId } from 'modules/useRestaurantId'
import GourmetSiteAccountSettingStack from 'navigation/GourmetSite/GourmetSiteAccountSettingStack'
import GourmetSiteCourseSettingStack from 'navigation/GourmetSite/GourmetSiteCourseSettingStack'
import GourmetSiteSeatSettingStack from 'navigation/GourmetSite/GourmetSiteSeatSettingStack'
import GourmetSiteSyncFailedReservationStack from 'navigation/GourmetSite/GourmetSiteSyncFailedReservationStack'
import LanguageStack from 'navigation/Other/LanguageStack'
import OtherStack from 'navigation/Other/OtherStack'
import TableMenuPagesStack from '../Menus/TableMenuPagesStack'
import TableMenusStack from '../Menus/TableMenusStack'
import TableProductOptionBoxesStack from '../Menus/TableProductOptionBoxesStack'
import TableProductsStack from '../Menus/TableProductsStack'
import TableUnlimitedOrderPlansStack from '../Menus/TableUnlimitedOrderPlansStack'
import TakeoutMenuPagesStack from '../Takeout/TakeoutMenuPagesStack'
import TakeoutMenusStack from '../Takeout/TakeoutMenusStack'
import TakeoutProductsStack from '../Takeout/TakeoutProductsStack'
import RestaurantCourseQuestionsStack from './CourseQuestionsStack'
import RestaurantCoursesStack from './CoursesStack'
import CrewMemberStack from './CrewMemberStack'
import ReservationsRoutes from './ReservationsRoutes'
import BroadcastStack from 'navigation/BroadcastStack/Stack'
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell'

function useDrawerRoutes() {
  const restaurantId = useRestaurantId()
  const drawerRoutes: Record<DrawerSectionKey, DrawerSection> = {
    Restaurant: {
      title: t('レストラン'),
      routes: {
        Reservation: {
          title: t('予約'),
          routes: [
            {
              title: t('予約'),
              fullPath: '/restaurants/:id/reservations',
              relativePath: '/reservations',
              exact: false,
              element: <ReservationsRoutes />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
        TableOrder: {
          title: t('注文'),
          routes: [
            {
              title: t('注文'),
              fullPath: '/restaurants/:id/table_orders',
              relativePath: '/table_orders',
              exact: false,
              element: <RestaurantTableOrdersStack />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
        RestaurantSettings: {
          title: t('設定'),
          routes: [
            {
              title: t('商品'),
              fullPath: `/restaurants/:id/products`,
              relativePath: '/products',
              exact: false,
              element: <TableProductsStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('オプション'),
              fullPath: `/restaurants/:id/product_option_boxes`,
              relativePath: '/product_option_boxes',
              exact: false,
              element: <TableProductOptionBoxesStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('カテゴリー'),
              fullPath: `/restaurants/:id/menu_pages`,
              relativePath: '/menu_pages',
              exact: false,
              element: <TableMenuPagesStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('メニュー'),
              fullPath: `/restaurants/:id/menus`,
              relativePath: '/menus',
              element: <TableMenusStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('放題プラン'),
              fullPath: `/restaurants/:id/unlimited_order_plans`,
              relativePath: '/unlimited_order_plans',
              exact: false,
              element: <TableUnlimitedOrderPlansStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('コース'),
              fullPath: '/restaurants/:id/courses',
              relativePath: '/courses',
              exact: false,
              element: <RestaurantCoursesStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('席'),
              fullPath: '/restaurants/:id/table_seats',
              relativePath: '/table_seats',
              element: <RestaurantTableSeatsStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('お店の情報'),
              fullPath: `/restaurants/:id/information`,
              relativePath: '/information',
              exact: false,
              element: <RestaurantInformationStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('お客様への質問'),
              fullPath: '/restaurants/:id/course_questions',
              relativePath: '/course_questions',
              exact: false,
              element: <RestaurantCourseQuestionsStack />,
              screenOptions: {
                headerShown: false,
              },
              hidden: true,
            },
            {
              title: t('営業時間'),
              fullPath: `/restaurants/:id/business_times`,
              relativePath: '/business_times',
              exact: false,
              element: <RestaurantBusinessTimesStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('担当者'),
              fullPath: `/restaurants/:id/crew_members`,
              relativePath: '/crew_members',
              exact: false,
              element: <CrewMemberStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('アカウント連携'),
              fullPath: '/restaurants/:id/gourmet_site/account_settings',
              relativePath: '/gourmet_site/account_settings',
              exact: false,
              element: <GourmetSiteAccountSettingStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('席の連携'),
              fullPath: '/restaurants/:id/gourmet_site/shared_seat_settings',
              relativePath: '/gourmet_site/shared_seat_settings',
              exact: false,
              element: <GourmetSiteSeatSettingStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('コースの取得'),
              fullPath: '/restaurants/:id/gourmet_site/course_settings',
              relativePath: '/gourmet_site/course_settings',
              exact: false,
              element: <GourmetSiteCourseSettingStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('連携ステータス'),
              DrawerLabel: SyncFailedReservationTitle,
              fullPath:
                '/restaurants/:id/gourmet_site/sync_failed_reservations',
              relativePath: '/gourmet_site/sync_failed_reservations',
              element: <GourmetSiteSyncFailedReservationStack />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
      },
    },
    TakeOut: {
      title: t('テイクアウト'),
      routes: {
        TakeoutSettings: {
          title: t('設定'),
          routes: [
            {
              title: t('商品'),
              fullPath: `/restaurants/:id/takeout/products`,
              relativePath: '/takeout/products',
              exact: false,
              element: <TakeoutProductsStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('カテゴリー'),
              fullPath: `/restaurants/:id/takeout/menu_pages`,
              relativePath: '/takeout/menu_pages',
              exact: false,
              element: <TakeoutMenuPagesStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('メニュー'),
              fullPath: `/restaurants/:id/takeout/menus`,
              relativePath: '/takeout/menus',
              exact: false,
              element: <TakeoutMenusStack />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
      },
    },
    Customers: {
      title: t('顧客管理'),
      routes: {
        Customer: {
          title: t('顧客'),
          routes: [
            {
              title: t('顧客'),
              fullPath: '/restaurants/:id/customers',
              relativePath: '/customers',
              element: <CustomersStack />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
        Broadcast: {
          title: t('顧客へ一斉送信'),
          routes: [
            {
              title: t('配信'),
              fullPath: '/restaurants/:id/broadcasts',
              relativePath: '/broadcasts',
              element: <BroadcastStack />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
        CustomerSettings: {
          title: t('設定'),
          routes: [
            {
              title: t('お客様属性'),
              fullPath: '/restaurants/:id/customer_attributes',
              relativePath: '/customer_attributes',
              element: <CustomerAttributes restaurantId={restaurantId} />,
            },
            undefined,
          ],
        },
      },
    },
    Analytics: {
      title: t('分析'),
      routes: {
        Reports: {
          title: t('日報・月報'),
          routes: [
            {
              title: t('日報・月報'),
              fullPath: '/restaurants/:id/reports',
              relativePath: '/reports',
              element: <ReportsStack />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
        Sales: {
          title: t('売上分析'),
          routes: [
            {
              title: t('レストラン'),
              fullPath: `/restaurants/:id/sales`,
              relativePath: '/sales',
              matchPath: [
                `/restaurants/:id/sales`,
                `/restaurants/:id/sales/by_payment`,
                `/restaurants/:id/sales/by_menu`,
                `/restaurants/:id/sales/by_category`,
                `/restaurants/:id/sales/by_product`,
              ],
              element: <RestaurantSales />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('テイクアウト'),
              fullPath: `/restaurants/:id/takeout/sales`,
              relativePath: '/takeout/sales',
              matchPath: [
                `/restaurants/:id/takeout/sales`,
                `/restaurants/:id/takeout/sales/by_payment`,
                `/restaurants/:id/takeout/sales/by_menu`,
                `/restaurants/:id/takeout/sales/by_category`,
                `/restaurants/:id/takeout/sales/by_product`,
              ],
              element: <TakeoutSales />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
      },
    },
    Other: {
      title: t('その他'),
      routes: {
        Other: {
          title: t('その他'),
          routes: [
            {
              title: t('言語設定'),
              fullPath: `/restaurants/:id/other/language`,
              relativePath: '/other/language',
              exact: false,
              element: <LanguageStack />,
              screenOptions: {
                headerShown: false,
              },
            },
            {
              title: t('契約状況'),
              fullPath: `/restaurants/:id/other/contracts`,
              relativePath: '/other/contracts',
              exact: false,
              element: <OtherStack />,
              screenOptions: {
                headerShown: false,
              },
            },
          ],
        },
      },
    },
  }

  return drawerRoutes
}

function useCurrentRestaurant() {
  const { id } = useParams<{ id: string }>()
  return useRestaurant(Number(id))
}

const drawerFooterHeight = 64
const drawerFooterArrowLeftIconSize = 32
const drawerFooterUserIconSize = 18

const dropdownFooterMenuWidth = 210
const dropdownFooterMenuItemHeight = 48

export function DrawerFooter({
  title,
  isAdmin,
}: {
  title: string
  isAdmin: boolean
}) {
  const navigate = useNavigate()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <View
      style={{
        flexDirection: 'row',
        height: drawerFooterHeight,
      }}
    >
      <LinkOpacity
        style={[
          {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: isAdmin ? 10 : 24,
          },
        ]}
        {...(isAdmin && {
          to: '/',
        })}
      >
        {isAdmin && (
          <View
            style={{
              width: drawerFooterArrowLeftIconSize,
              height: drawerFooterArrowLeftIconSize,
              borderRadius: drawerFooterArrowLeftIconSize / 2,
              backgroundColor: Colors.bgBlack,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              size={12}
              color={Colors.disabledBlack}
            />
          </View>
        )}
        <Text
          style={{
            marginLeft: isAdmin ? 12 : 0,
            color: Colors.secondaryBlack,
          }}
        >
          {title}
        </Text>
      </LinkOpacity>

      <TouchableOpacity
        onPress={() => setIsMenuOpen(!isMenuOpen)}
        style={{
          borderLeftWidth: 0.5,
          borderLeftColor: Colors.border,
          alignItems: 'center',
          justifyContent: 'center',
          paddingHorizontal: 20,
        }}
      >
        <FontAwesomeIcon
          icon={faUserAlt}
          size={drawerFooterUserIconSize}
          color={Colors.disabledBlack}
        />
      </TouchableOpacity>
      <View
        style={{
          display: isMenuOpen ? 'flex' : 'none',
          position: 'absolute',
          right: -dropdownFooterMenuWidth - 8,
          bottom: 8,
          width: dropdownFooterMenuWidth,
          background: Colors.white,
          borderRadius: 4,
          shadowColor: 'rgba(0,0,0,0.24)',
          shadowOffset: {
            width: 0,
            height: 7,
          },
          shadowOpacity: 0.24,
          shadowRadius: 8,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            navigate('/business_user/account/edit')
          }}
          style={{
            paddingHorizontal: 20,
          }}
        >
          <View
            style={{
              justifyContent: 'center',
              height: dropdownFooterMenuItemHeight,
            }}
          >
            <Text
              style={{
                color: Colors.black,
                fontSize: 14,
              }}
            >
              {t('アカウント')}
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            signOut()
          }}
          style={{
            paddingHorizontal: 20,
          }}
        >
          <View
            style={{
              justifyContent: 'center',
              height: dropdownFooterMenuItemHeight,
            }}
          >
            <Text
              style={{
                color: Colors.caution,
                fontSize: 14,
              }}
            >
              {t('ログアウト')}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default function RestaurantsDrawer() {
  const { width, md } = useResponsive()
  const drawerRoutes = useDrawerRoutes()

  const { owner } = useCurrentOwner()
  const { actor } = useAuthMe()
  const { restaurant } = useCurrentRestaurant()

  const renderDrawerItemIcon = ({
    routeKey,
    focused,
  }: {
    routeKey: DrawerRouteKey
    focused: boolean
  }) => {
    if (routeKey === 'GourmetSite') {
      return <GourmetSiteSettingIcon />
    }

    let icon: IconDefinition | null = null
    switch (routeKey) {
      case 'Reservation':
        icon = faCalendarAlt
        break
      case 'TableOrder':
      case 'TakeoutOrder':
        icon = faBurgerSoda
        break
      case 'RestaurantSettings':
      case 'TakeoutSettings':
      case 'CustomerSettings':
        icon = faCog
        break
      case 'Customer':
        icon = faAddressBook
        break
      case 'Broadcast':
        icon = faBell
        break
      case 'Reports':
        icon = faClipboardList
        break
      case 'Sales':
        icon = faChartLine
        break
      case 'Other':
        icon = faEllipsisH
        break
    }

    if (icon === null) return null

    return (
      <FontAwesomeIcon
        icon={icon}
        size={16}
        color={focused ? Colors.primary : Colors.black40}
      />
    )
  }

  if (restaurant === undefined || owner === undefined || actor === undefined)
    return null

  const isAdmin =
    actor.oneofKind === 'owner' ||
    (actor.oneofKind === 'businessUser' &&
      actor.businessUser.role === BusinessUserResource_Role.ADMIN)

  return (
    <>
      <Drawer
        drawerRoutes={drawerRoutes}
        drawerHeader={
          <DrawerHeader
            title={restaurant.name}
            isRestaurant
            isAdmin={isAdmin}
            iconLeft={
              <Icon
                imageUrl={restaurant.logo?.sm}
                fallbackText={restaurant.name}
                width={width < md ? 36 : 40}
              />
            }
            iconRight={<DrawerHeaderIconRight />}
            onPressSignOut={() => {
              signOut()
            }}
          />
        }
        renderDrawerItemIcon={renderDrawerItemIcon}
      />
    </>
  )
}
