import React from 'react'
import { ScrollView, View } from 'react-native'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { useRestaurantId } from 'modules/useRestaurantId'
import { useToken } from 'models/Auth'
import useSWR from 'swr'
import { swrKey } from '@hello-ai/ar_shared/src/modules/swr'
import { fetcher } from 'modules/swr'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export interface RestaurantContract {
  id: 'order' | 'takeout' | 'reservation_book'
  enabled: boolean
  service_name: string
  status: string
  term: string
  monthly_fee: string
  notes: string
  plan_type?: string
  plan?: string
}

export function useRestaurantContracts(restaurantId: number) {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    Array<RestaurantContract>,
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/restaurants/${restaurantId}/contracts`), fetcher)

  return {
    data,
    error,
    mutate,
  }
}

function Services({
  service,
  index,
}: {
  service: RestaurantContract
  index: number
}) {
  const monthlyFees = service.monthly_fee.split('\n')
  const monthlyFee = monthlyFees.shift()

  return (
    <View
      key={service.service_name}
      style={{
        borderTopWidth: index === 0 ? 0 : 0.5,
        borderColor: Colors.border,
        padding: 24,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <View style={{ flex: 5 }}>
        <Text style={{ flex: 4, fontSize: 18 }}>{service.service_name}</Text>
        {service.id === 'reservation_book' && service.plan !== 'special' && (
          <Text style={{ flex: 4, fontSize: 10 }}>{service?.plan_type}</Text>
        )}
      </View>
      <View style={{ flex: 2, alignItems: 'flex-start' }}>
        <View
          style={[
            { borderRadius: 2, padding: 8 },
            service.status === t('契約中') || service.status === t('公開')
              ? {
                  backgroundColor: Colors.accentBg,
                }
              : {
                  backgroundColor: Colors.black6,
                },
          ]}
        >
          <Text
            style={[
              { fontWeight: '600', fontSize: 14, lineHeight: 14 },
              service.status === t('契約中') || service.status === t('公開')
                ? {
                    color: Colors.accent,
                  }
                : {
                    color: Colors.black60,
                  },
            ]}
          >
            {service.status}
          </Text>
        </View>
      </View>
      <Text style={{ flex: 3, fontSize: 16 }}>{service.term}</Text>
      <View style={{ flex: 5 }}>
        <Text style={{ fontSize: 18, fontWeight: '600', color: Colors.black }}>
          {monthlyFee}
        </Text>
        {monthlyFees?.length > 0 && (
          <>
            {monthlyFees.map((fee) => (
              <Text key={fee} style={{ fontSize: 12, color: Colors.black60 }}>
                {fee}
              </Text>
            ))}
          </>
        )}
      </View>
      <Text style={{ flex: 5, fontSize: 12, color: Colors.black60 }}>
        {service.notes}
      </Text>
    </View>
  )
}

export default function ContractsScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const { data } = useRestaurantContracts(restaurantId)

  return (
    <ScrollView
      contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
      style={{ backgroundColor: Colors.bgBlack }}
    >
      <View>
        <Text style={{ fontSize: 22, fontWeight: '600' }}>
          {t('サービス利用状況')}
        </Text>
        <ShadowBox style={{ marginTop: 24, padding: 0 }}>
          {width > sm && (
            <View
              style={{
                padding: 24,
                flexDirection: 'row',
                borderBottomWidth: 1,
                borderColor: Colors.border,
                alignItems: 'flex-end',
              }}
            >
              <Text style={{ flex: 4, fontWeight: '600', fontSize: 14 }}>
                {t('サービス')}
              </Text>
              <Text style={{ flex: 2, fontWeight: '600', fontSize: 14 }}>
                {t('ステータス')}
              </Text>
              <Text style={{ flex: 3, fontWeight: '600', fontSize: 14 }}>
                {t('契約期間')}
              </Text>
              <Text style={{ flex: 5, fontWeight: '600', fontSize: 14 }}>
                {t('月額料金(税込)')}
              </Text>
              <Text style={{ flex: 5, fontWeight: '600', fontSize: 14 }}>
                {t('備考')}
              </Text>
            </View>
          )}
          {data &&
            data.map((service, index) => {
              const monthlyFees = service.monthly_fee.split('\n')
              const monthlyFee = monthlyFees.shift()
              return width < sm ? (
                <View
                  style={{
                    marginHorizontal: 16,
                    paddingVertical: 24,
                    borderBottomWidth: 1,
                    borderColor: Colors.border,
                  }}
                >
                  <Text style={{ flex: 4, fontSize: 18, marginBottom: 24 }}>
                    {service.service_name}
                  </Text>
                  {service.id === 'reservation_book' &&
                    service.plan !== 'special' && (
                      <Text style={{ flex: 4, fontSize: 10 }}>
                        {service?.plan_type}
                      </Text>
                    )}
                  <View
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginBottom: 20,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: '600',
                        fontSize: 14,
                        lineHeight: 14,
                        flex: 2,
                      }}
                    >
                      {t('ステータス')}
                    </Text>
                    <View
                      style={{
                        display: 'flex',
                        flex: 3,
                      }}
                    >
                      <Text
                        style={[
                          {
                            width: 60,
                            fontWeight: '600',
                            fontSize: 14,
                            borderRadius: 2,
                            padding: 8,
                            lineHeight: 14,
                          },
                          service.status === t('契約中') ||
                          service.status === t('公開')
                            ? {
                                color: Colors.accent,
                              }
                            : {
                                color: Colors.black60,
                              },
                          service.status === t('契約中') ||
                          service.status === t('公開')
                            ? {
                                backgroundColor: Colors.accentBg,
                              }
                            : {
                                backgroundColor: Colors.black6,
                              },
                        ]}
                      >
                        {service.status}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginBottom: 20,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: '600',
                        fontSize: 14,
                        lineHeight: 14,
                        flex: 2,
                      }}
                    >
                      {t('契約期間')}
                    </Text>
                    <Text style={{ flex: 3, fontSize: 16 }}>
                      {service.term}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: 20,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: '600',
                        fontSize: 14,
                        lineHeight: 14,
                        flex: 2,
                      }}
                    >
                      {t('月額料金(税込)')}
                    </Text>
                    <View
                      style={{
                        flex: 3,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontWeight: '600',
                          color: Colors.black,
                        }}
                      >
                        {monthlyFee}
                      </Text>
                      {monthlyFees?.length > 0 && (
                        <>
                          {monthlyFees.map((fee) => (
                            <Text
                              key={fee}
                              style={{ fontSize: 12, color: Colors.black60 }}
                            >
                              {fee}
                            </Text>
                          ))}
                        </>
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: 20,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: '600',
                        fontSize: 14,
                        lineHeight: 14,
                        flex: 2,
                      }}
                    >
                      {t('備考')}
                    </Text>
                    <Text
                      style={{ flex: 3, fontSize: 12, color: Colors.black60 }}
                    >
                      {service.notes}
                    </Text>
                  </View>
                </View>
              ) : (
                <Services
                  key={`service-${index}`}
                  service={service}
                  index={index}
                />
              )
            })}
        </ShadowBox>
      </View>
    </ScrollView>
  )
}
